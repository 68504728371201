import { Component, HostListener, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { interval, Subject, tap } from 'rxjs';
import { SwPush, SwUpdate, VersionEvent } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { BotService, ConfigurationService, LoaderService, LocalstorageService, NotificationService } from '@algomusinc/services';
import { BOT_TOKEN_INFO, DYNAMIC_DIALOG_CONFIG } from '@algomusinc/domain';
import { environment } from '../environments/environment';
import * as Highcharts from 'highcharts';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { VersionUpdateComponent } from './version-update/version-update.component';
import Draggable from 'highcharts/modules/draggable-points.js';
Draggable(Highcharts);

import Exporting from 'highcharts/modules/exporting.js';
Exporting(Highcharts);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Algo';
  isParentPortal = false;
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  timeOutDuration: any;
  isUpdatedVersion = false;
  version: any = '12345'
  @ViewChild('confirmTemp') confirmTemp!: TemplateRef<any>;
  public dialogDefaultConfig: DynamicDialogConfig =JSON.parse(DYNAMIC_DIALOG_CONFIG.STATIC) 
  constructor(
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    public dialog: DialogService,
    public router: Router,
    public route: ActivatedRoute,
    public storage: LocalstorageService,
    public botService: BotService,
    public loaderService: LoaderService
  ) {}
  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      setTimeout(() => {
        this.checkForUpdates();
      }, 5000);
      setInterval(() => {
        try {
          this.swUpdate.checkForUpdate().then(() => console.log('checking for updates'));
        } catch (err) {
          console.log(err);
        }
      }, 5000)
    }
    this.timeOutDuration = environment.idleTime * 60 * 3600;
    this.setTimeout();
  }

  public refreshBotToken() {
    this.botService.RefreshBotToken().subscribe(res => {
      this.storage.set(BOT_TOKEN_INFO, res.token);
    }, error => {
      console.log('Error => ', 'Token not refreshed', error)
    });
  }
  public checkForUpdates(): void {
      this.swUpdate.versionUpdates.pipe(
        tap(update => {
            if (update.type === 'VERSION_DETECTED') {
                // activateUpdate() will trigger the 'VERSION_READY' or 'VERSION_INSTALLATION_FAILED' event when done
                console.log('New server version detected, trying to install...');
                this.swUpdate.activateUpdate().then();
            }
            if (update.type === 'VERSION_READY') {
                // this._reloadPage will be set to true, asking a full page reload on next navigation
                console.log('New server version installed');
                this.promptUser();
            }
            if (update.type === 'VERSION_INSTALLATION_FAILED') {
                // this._clearCacheAndReload will be set to true, asking a cache clear and full page reload on next navigation
                console.warn('Error while installing update, cache will be cleared and page reloaded');
            }
            // another event type possible is 'NO_NEW_VERSION_DETECTED', we don't need to handle it
        })
    ).subscribe();
  }

  private promptUser(): void {
    const ref = this.dialog.open(VersionUpdateComponent, {
      ...this.dialogDefaultConfig,
    });
    ref.onClose.subscribe(res => {
        this.reload();
    })
  }
  checkVersion() {
    navigator.serviceWorker
      .register('/ngsw-worker.js')
      .then((reg: ServiceWorkerRegistration) => {
        // this.dialog.open(this.confirmTemp, {
        //   hasBackdrop: true,
        //   disableClose: true,
        // }).afterClosed().subscribe(res => {
        console.log('ngsw-worker:s', reg);
        reg.update();
        // });
      });
  }


  setTimeout() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      this.timeOutDuration
    );
  }

  @HostListener('window:mousemove') refreshUserStateWhenMouseMoves() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  @HostListener('window:click') refreshStateWhenClicked() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  reload() {
    this.isUpdatedVersion = false;
    localStorage.clear();
    sessionStorage.clear();
    this.deleteAllCookies();
    window.location.reload();
  }
  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
  onUpdate(values: any) {
    console.log(values);
  }
}
